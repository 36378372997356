export const transformToOptions = (items: string[]) =>
  items.map((item: string) => ({ label: item, value: item }))

export const Regions = [
  `Africa`,
  `Asia`,
  `Caribbean`,
  `Central America`,
  `Europe`,
  `Middle East`,
  `North America`,
  `Oceania`,
  `South America`,
]

export const regionsOptions = transformToOptions(Regions)

export const Platforms = [
  `Contentful`,
  `Contentstack`,
  `DatoCMS`,
  `Drupal`,
  `HubSpot`,
  `Hygraph`,
  `Kontent`,
  `Prismic`,
  `Sanity`,
  `Segment.io`,
  `Shopify`,
  `Storyblok`,
  `Strapi`,
  `WordPress`,
]
export const platformsOptions = transformToOptions(Platforms)

export const Expertise = [`eCommerce`, `Design`, `UI/UX design`, `Content`]
export const expertiseOptions = transformToOptions(Expertise)
