import * as React from "react"
import { Heading, Text, Spacer, Link } from "gatsby-interface"

export function IntakeFormConfirmation() {
  return (
    <React.Fragment>
      <Heading as="h2">Thank you!</Heading>

      <Spacer size={6} />

      <Text>
        Thank you for submitting your agency for inclusion in the Gatsby Partner
        Agency Index.
      </Text>
      <Text>
        Please note that completing the submission form does not guarantee
        inclusion in the agency index. We will contact agency representatives
        directly (using the contact information provided via this form) within
        3-4 weeks after submission.
      </Text>
      <Text>
        Want to learn more about how Gatsby can help agencies build modern,
        blazing-fast client websites? Download our free ebook on{" "}
        <Link href="https://www.gatsbyjs.com/gatsby-for-agencies-ebook/">
          Gatsby for Web Design Agencies
        </Link>
        , or check out some of the{" "}
        <Link href="https://www.gatsbyjs.com/blog/tags/agencies/">
          agency-focused content
        </Link>{" "}
        in the Gatsby blog.
      </Text>
    </React.Fragment>
  )
}
