export function getLanguagesList(nodes: { languages?: string[] }[] = []) {
  if (!nodes.length) {
    return []
  }

  return nodes
    .reduce<string[]>((acc, cur) => {
      const agencyLanguages = cur.languages || []

      agencyLanguages.forEach((item: string) => {
        if (!acc.includes(item)) {
          acc.push(item)
        }
      })

      return acc
    }, [])
    .sort()
}
