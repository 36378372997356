import * as Yup from "yup"
import { ui as uiText } from "gatsby-theme-dashboard/src/modules/locales/default"

export type FormValues = {
  introduction: string
  specialization: string
  regions: string
  languages: string
  otherLanguages: string
  expertises: string
  platforms: string
  name: string
  website: string
  email: string
  phone: string
  contact: string
  logo: string
  site1: string
  site2: string
  site3: string
}

export const validationSchema = Yup.object().shape<FormValues>({
  introduction: Yup.string()
    .test(
      "intro",
      `Introduction copy is too long`,
      (val) => !val || (val && val.split(` `).length <= 100)
    )
    .required(`Introduction copy is required`),
  specialization: Yup.string()
    .test(
      "specialization",
      `Specialization field is too long`,
      (val) => !val || (val && val.split(` `).length <= 100)
    )
    .required(`Specialization field is required`),
  regions: Yup.string().required(`Regions field is required`),
  languages: Yup.string().required(`Suported languages field is required`),
  otherLanguages: Yup.string(),
  expertises: Yup.string().required(`Expertise field is required`),
  platforms: Yup.string().required(`Suported platforms field is required`),
  name: Yup.string().required(`Name is required`),
  website: Yup.string().url().required(`Website url is required`),
  email: Yup.string().email().required(`Email address is reqired`),
  phone: Yup.string().required(`Phone number is required`),
  contact: Yup.string().required(`Contact name is required`),
  logo: Yup.string()
    .url()
    .test(
      "logo",
      `Logo must be a svg or png file`,
      (val) => val && (val.endsWith(".svg") || val.endsWith(`.png`))
    )
    .required(`Logo field  is required`),
  site1: Yup.string(),
  site2: Yup.string(),
  site3: Yup.string(),
})
